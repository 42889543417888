import React, { Component } from 'react';

import { fetchApi } from '../../../fetch';
import { getSerializedObject } from '../../../serializers';
import { FieldWithLabel, Select, Text } from '../../common';
import { customSelectStyles } from '../../vars/vars';

const EMPTY_VALUE = {
    value: 0,
    label: 'Wpisz co najmniej 3 litery...',
}
const SELECT_CLASSNAME = 'select-custom';
const SELECT_INVALID_CLASSNAME = 'select-custom select-custom--invalid';


class FormMiejscowosc extends Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({
            miejscowosc: props.miejscowosc || null,
            selectMiejscowoscMenuIsOpen: false,
            inputOptions: [],
            moreOptionsAvailable: false,
            fetchError: '',
        });

        this.requestId = 0;

        this.xhrFetch = null;
        this.xhrTimeout = null;

        this.handleChangeInputMiejscowosc = this.handleChangeInputMiejscowosc.bind(this);
        this.handleFetchSuccess = this.handleFetchSuccess.bind(this);
        this.handleFetchError = this.handleFetchError.bind(this);
        this.handleFetchIncorrectStatus = this.handleFetchIncorrectStatus.bind(this);
        this.handleBlurMiejscowosc = this.handleBlurMiejscowosc.bind(this);
        this.handleChangeMiejscowosc = this.handleChangeMiejscowosc.bind(this);
    }

    // basic functions

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }
    }

    // handlers

    handleChangeInputMiejscowosc(inputValue) {
        if (this.xhrTimeout !== null) {
            clearTimeout(this.xhrTimeout);
            this.xhrTimeout = null;
        }
        if (inputValue.length < 3) {
            this.setState({
                selectMiejscowoscMenuIsOpen: false,
                inputOptions: [],
                moreOptionsAvailable: false,
                fetchError: ''
            });
        } else {
            this.requestId++;
            this.xhrTimeout = setTimeout(() => {
                this.setState(
                    {inputOptions: [], moreOptionsAvailable: false, fetchError: ''},
                    () => {
                        // get data from server
                        this.xhrFetch = fetchApi(
                            '/api/dictionaries/teryt',
                            'GET',
                            {},
                            {locality_search_key: inputValue, request_id: this.requestId},
                            this.handleFetchSuccess,
                            this.handleFetchError,
                            this.handleFetchIncorrectStatus,
                        );
                    }
                );
            }, 500);
        };
    }

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        if (parseInt(data.request_id) < this.requestId) {
            // do nothing, there is another fetch on the fly
            return
        }
        this.setState({
            selectMiejscowoscMenuIsOpen: true,
            inputOptions: (getSerializedObject(data).teryty || []).sort(this.sortMiejscowosc).map(
                m => ({value: m, label: `${m.miejscowosc} (woj. ${m.wojewodztwo}, pow. ${m.powiat}, gm. ${m.gmina})`})),
            moreOptionsAvailable: data.meta.last_page > 1,
        });
    }

    handleFetchError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            fetchError: `Nie udało się pobrać miejscowości. ${data.message}`,
        });
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            fetchError: `Nie udało się pobrać miejscowości. Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    handleBlurMiejscowosc(ev) {
        this.setState({selectMiejscowoscMenuIsOpen: false});
    }

    handleChangeMiejscowosc(selectedOption) {
        const value = selectedOption === null ? null : selectedOption.value;
        this.setState(
            {
                selectMiejscowoscMenuIsOpen: false,
                miejscowosc: value,
            },
            () => this.props.changeMiejscowosc(value)
        );
    }

    // rendering

    render() {
        const { error, formLocked, isClearable, invalid } = this.props;
        const {
            fetchError,
            inputOptions,
            miejscowosc,
            moreOptionsAvailable,
            selectMiejscowoscMenuIsOpen,
        } = this.state;

        const moreOptionsAvailableText = 'Istnieje więcej pasujących wyników. Jeśli na liście nie ma szukanej miejscowości, wpisz dodatkowe litery.'

        return (
            <FieldWithLabel
                label="Miejscowość"
                tag="label"
                selectRef={React.createRef()}
            >
                {moreOptionsAvailable && <Text info>{moreOptionsAvailableText}</Text>}
                <Select
                    aria-label={`Miejscowość, wpisz co najmniej 3 litery ${moreOptionsAvailable ? moreOptionsAvailableText : ''} ${invalid ? error : ''} ${fetchError.length > 0 ? fetchError : ''}`}
                    className={invalid ? SELECT_INVALID_CLASSNAME : SELECT_CLASSNAME}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                    }}
                    isClearable={isClearable}
                    isDisabled={formLocked}
                    menuIsOpen={selectMiejscowoscMenuIsOpen}
                    noOptionsMessage={() => 'Brak wybranej opcji'}
                    options={inputOptions}
                    placeholder={isClearable ? EMPTY_VALUE.label : ''}
                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                    styles={{
                        ...customSelectStyles,
                        menuPortal: (provided, state) => ({
                          ...provided,
                          maxHeight: "160px",
                        }),
                        menuList: (provided, state) => ({
                          ...provided,
                          maxHeight: "160px",
                        }),
                    }}        
                    value={miejscowosc
                        ? {
                            value: miejscowosc,
                            label: `${miejscowosc.miejscowosc} (woj. ${miejscowosc.wojewodztwo}, pow. ${miejscowosc.powiat}, gm. ${miejscowosc.gmina})`
                        } : isClearable ? null : EMPTY_VALUE}
                    onBlur={this.handleBlurMiejscowosc}
                    onChange={this.handleChangeMiejscowosc}
                    onInputChange={this.handleChangeInputMiejscowosc}
                />
                {invalid && <Text error>{error}</Text>}
                {fetchError.length > 0 && <Text error>{fetchError}</Text>}
            </FieldWithLabel>
        )
    }
}


export { FormMiejscowosc };
